export const getTextShort = (text,length) => {
    return text?.length > length ? text.substr(0,length) + '...' : text
}

export const getDateTime = (date,full = false) => {
    const Month = {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre'
    }
    if(date){
        const dateTime = date.split(':')
        let times = dateTime[0].split(' ') 
        times=times[0].split("-")
        return full ? [times[2]] + " de " + Month[parseInt(times[1])]+ ' ' + times[0] : Month[parseInt(times[1])] + " " + times[0]
    }
    return 'Enero 2020'
}

export const getFeatured = (novelties) => {
    if(novelties){
      return novelties.filter(item => item.featured === 'true' || item.featured === '1' || item.featured === true)
    }
}

export const getThree = (novelties) => {
    return novelties.filter((item,index) => item.is_active.toString() === "true" || item.is_active.toString() === "1").slice(0,3)
}

export const getCompleteName = (noveltie) => {
    return (noveltie?.creator?.first_name && 
            noveltie?.creator?.last_name)
            ? (`${noveltie?.creator?.first_name} ${noveltie?.creator?.last_name}`)
            : 'Anonimo';
}